@font-face {
    font-family: 'MonumentExtended-Regular';
    src: local('MonumentExtended-Regular'),
    local('MonumentExtended-Regular'),
    url('./fonts/MonumentExtended-Regular.otf');
}

@font-face {
    font-family: 'MonumentExtended-Ultrabold';
    src: local('MonumentExtended-Ultrabold'),
    local('MonumentExtended-Ultrabold'),
    url('./fonts/MonumentExtended-Ultrabold.otf');
}

body {
    margin: 0;
    font-family: 'MonumentExtended-Ultrabold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.about {
    height: auto;
}

@media (min-width: 400px) {
    .about {
        height: 100%;
    }

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
    text-align: center;
    font-size: 12px;
    margin: 0;
}

h2 {
    margin: 0;
}

h4 {
    margin: 0;
}
