html, body {
    -webkit-overflow-scrolling: touch;
    /*font-size: 16px;*/
}

::-webkit-scrollbar {
    /*display: none;*/
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
}

.rowCenter {
    justify-content: center;
}

.rowBottom {
    align-items: flex-end;
}

.rowSpace {
    flex: 0.15
}

.rowXsSpace {
    flex: 0.075
}

.rowTop {
    justify-content: start;
    align-content: start;
}

.line {
    margin-bottom: .32rem;
}

.sideMargin {
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
    margin-bottom: 1.5625rem;
    margin-top: 1.5625rem;
}

.smallSideMargin {
    margin-left: 0rem;
    margin-right: 0rem;
    margin-bottom: 1.5625rem;
    margin-top: 1.5625rem;
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.columnVerticalCenter {
    align-items: center;
}

.columnSpaceBetween {
    justify-content: space-between;
    height: 100%
}

.shrink {
    flex: 0 auto;
}

.grow {
    flex-grow: 100;
}

.absoluteCenter {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.absoluteCenter * {
    pointer-events: all;
}

.absoluteTop {
    position: absolute;
    top: 0;
}

.absoluteBottom {
    position: absolute;
    bottom: 0;
}

.curtain {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.separator {
    width: 100%;
    height: 1px;
}

textarea {
    font-size: .875rem;
}

.screen {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Loader */

.loader,
.loader:after {
    border-radius: 50%;
    width: 3.25em;
    height: 3.25em;
}

.loader {
    font-size: 16px;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
